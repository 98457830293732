import React from 'react';
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { BsArrowRight } from "react-icons/bs"
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/layout"
import SEO from '../components/seo';

const TopBanner = styled.div`
    margin-top: 80px;
    padding-top: 140px;
    padding-bottom: 140px;
    background-color: rgba(0, 66, 37, 0.4);
    display: flex;
    justify-content: center;
    align-items: center !important;

    @media (max-width: 768px) {
        margin-top: 70px;
    }
`

const BlogTitle = styled.h1`
    color: #FFFFFF;
    text-align: center;
    font-size: 38px;
    font-weight: 900;
    font-family: 'Raleway', sans-serif !important;
    padding: 10px;
    display: inline;
    margin-bottom: 0;
    @media (max-width: 450px) {
    font-size: 9vw;
    }
`

const LowerSection = styled.div`
    background-color: #FFFFFF;
    padding-top: 20px;
    padding-bottom: 10px;
    .title{
        font-family: 'Raleway', sans-serif !important;
        font-size: 38px;
        color: rgba(0, 66, 37, 1);
        font-weight: 900;
        text-align: center;
        margin-bottom: 40px;
    }
`

const BlogIndex = styled.div`
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: 1fr !important;
    grid-template-rows: auto;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
    @media (max-width: 768px) {
        grid-template-columns: 1fr!important;
    }
    @media (max-width: 450px) {
        grid-template-columns: 1fr !important;
        padding-bottom: 10vh;
        padding-left: 20px;
        padding-right: 20px;
    }
`

const BlogContainer = styled(Link)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    align-content: flex-start;
    width: 100%;
    cursor: pointer;
    text-decoration: none;
    font-family: 'Cormorant Garamond', serif !important;

    
    border-radius: 2px !important;
    overflow: hidden;

`

const BlogImage = styled(Img)`
    width: 100%;
    

`

const BlogBlurb = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    background-color: rgba(0, 66, 37, 0.4);
    font-family: 'Cormorant Garamond', serif !important;
    padding: 20px;
    flex: 1;
    transition: all 300ms linear 0s ;

    &:hover {
        background-color: rgba(0, 66, 37, 1) !important;
        transition: all 300ms linear 0s ;
    }

    .read-more {
        color: #FFFFFF;
        font-size: 38px;
        
    }
`

const PostTitle = styled.div`
    color: #FFFFFF;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    

    .blog-title{
        font-size: 24px; 

    }

    .blog-date {
        font-weight: 700;
        font-size: 18px;
    }
    
    @media (max-width: 450px) {
        .blog-date {
            font-size: 3.5vw;
        }

        .blog-title {
            font-size: 5.5vw;
        }
    }
`

const Excerpt = styled.blockquote`
    font-family: 'Cormorant Garamond', serif !important;
    margin-top: 30px;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;

    @media (max-width: 450px) {
        text-align: justify;
    }

    
`

const BlogLink = styled(Link)`
    display: inline-block;
    color: white;
    background-color: #004225;
    transition: all 300ms linear 0s ;
    border-radius: 2px;
    margin: 30px auto;
    padding: 10px 15px;
    text-decoration: none;
    font-size: 18px;
    font-family: 'Raleway', sans-serif !important;
    font-weight: 700;
    

    &:hover{
        background-color: #C4C4C4;
        transition: all 300ms linear 0s ;
    }
`

const Pagination = styled.div`
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: 1fr 1fr !important;
    grid-template-rows: auto;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 40px;
    padding-right: 40px;

    @media (max-width: 450px) {
        display: flex !important;
        justify-content: space-between !important;
        padding-left: 20px;
        padding-right: 20px;
    }

`

const PageButton = styled.div`
    display: flex;
    
    .arrow{
        font-size: 10px;
    }

    .page-button{
        text-decoration: none;
        display: block;
        padding: 10px;
        background-color: rgba(0, 66, 37, 1) !important;
        color: #FFFFFF;
        border-radius: 2px;
        font-size: 16px;
        font-weight: 700;
    }

    .page-button:hover {
        background-color: #C4C4C4 !important;
        transition: all 300ms linear 0s ;
    }

`

const PageNumberButtons = styled.div`
    display: flex;
    justify-content: space-around;
    flex-flow: row wrap;

    .page-number{
        text-decoration: none;
        display: block;
        padding: 10px;
        background-color: #00aeef;
        color: #FFFFFF;
        border-radius: 2px;
        font-size: 14px !important;
        font-weight: 700;
    }

    .page-number:hover {
        background-color: #0a3b7c;
        transition: all 300ms linear 0s ;
    }

    @media (max-width: 450px) {
        display: none
    }

`

const BottomNewsLink = styled(Link)`
    margin: 0 auto;
    display: inline-block;
    color: rgba(0, 66, 37, 1);
    text-decoration: none;
    font-family: 'Raleway', sans-serif !important;
    font-weight: 800;
    text-align: center;
    font-size: 38px;

    .newslink{
        font-family: 'Raleway', sans-serif !important;
        font-weight: 700;
        text-align: center;
        font-size: 18px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
        padding-bottom 5px;
    }

    &:hover{
        .newslink{
            border-bottom: 2px solid rgba(0, 66, 37, 1);
            transition: all 300ms linear 0s;
        }
    }
`

const Break = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding: 0 30px;
  .line{
    max-width: 800px;
    height: 3px;
    margin: 20px auto;
    background-color: #C4C4C4;
    border-radius: 3px;
    
  }
  
`


const ArticleList = (  ) => {


    const data = useStaticQuery(graphql`
        query{
            article:allContentfulArticle(sort: {fields: date, order: DESC}
                limit: 3
                ) {
                edges {
                    node {
                        slug
                        tItle
                        date(formatString: "DD MMMM YYYY")
                        featuredImage {
                            fluid(maxHeight: 2000){
                                ...GatsbyContentfulFluid
                            }
                                
                        }
                        contentful_id
                        excerpt {
                        excerpt
                        }
                    }
                }
            }
        }
    `)

    return (  
        <div>
            <LowerSection>
            <h1 style={{ fontFamily: "'Cormorant Garamond', serif" }} className="title">Latest News</h1>
                <BlogIndex >
                    {data.article.edges.map((edge)=>{
                        return (
                            <div>
                            <BackgroundImage fluid={edge.node.featuredImage.fluid}>    
                            <BlogContainer key={edge.node.contentful_id} to={`/${edge.node.slug}`} style={{borderRadius: "15px"}}>
                                <BlogBlurb >
                                    <PostTitle >
                                        <div>
                                            <h1 style={{ textAlign: "center" }} className="blog-title">{edge.node.tItle}</h1>
                                        </div>
                                        <p style={{ textAlign: "center", scrollPaddingBottom: "20px", marginBottom: "0" }} className="blog-date">{edge.node.date}</p>

                                    </PostTitle>
                                </BlogBlurb>
                            </BlogContainer>
                            </BackgroundImage>
                            <Excerpt>{edge.node.excerpt.excerpt}</Excerpt>
                            <div style={{display: "flex", alignItems: "center"}}>
                            <BlogLink key={edge.node.contentful_id} to={`/${edge.node.slug}`}>Read more</BlogLink>
                            </div>
                            <Break>
                                <div className="line"></div>
                            </Break>

                            </div>
                        )
                    })}

                </BlogIndex>
                <div style={{margin: "0px auto 20px auto", width: "100%", display: "flex", justifyContent: "center" }}>
                    <BottomNewsLink to="/articles"><h1 className="newslink">View all</h1></BottomNewsLink>
                </div>
            </LowerSection>
        </div>            
    );
}
 
export default ArticleList;

// export const pageQuery = graphql`
//     query {
//     article:allContentfulArticle(sort: {fields: date, order: DESC}
//         limit: 3
//         ) {
//         edges {
//             node {
//                 slug
//                 tItle
//                 date(formatString: "DD MMMM YYYY")
//                 featuredImage {
//                     fluid(maxHeight: 2000){
//                         ...GatsbyContentfulFluid
//                     }
                        
//                 }
//                 contentful_id
//                 excerpt {
//                 excerpt
//                 }
//             }
//         }
//     }
//     contentfulArticlePage {
//         articlePageTitle
//         articlePageLinkTitle
//         articlePageLinkDescription
//         articlePagePreviewImage {
//           fluid(maxHeight: 2000){
//             ...GatsbyContentfulFluid
//           }
            
//           file {
//             url
//           }
//         }
//         articlePageBannerTitle
//         articlePageBanner {
//           fluid(maxWidth: 2000){
//             ...GatsbyContentfulFluid
//           }
//         }
//     }
//     site {
//         siteMetadata {
//             url
//         }
//     }
// }
// `;



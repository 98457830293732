import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import styled from "styled-components"
import FadeIn from 'react-fade-in';
import SEO from "../components/seo"
import Layout from "../components/layout"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ValuesList from "../components/values-list"
import ArticleList from "../components/article-list"



const IndexPage = ( { data } ) => {

        const options = {
          renderNode: {
              "embedded-asset-block": (node) => {

                  const url = node.data.target.fields.file["en-US"].url
                  const alt = node.data.target.fields.title["en-US"]

                  return <img style={{ width: "100%" }} alt={alt} src={url}/>
              }
          }
        }
  
      return(
        <Layout>
          <SEO 
          pageType="website"
          linkTitle={data.homePage.landingPageLinkTitle}
          pageDescription={data.homePage.landingPageLinkDescription}
          pageURL={data.site.siteMetadata.url}
          pageTitle={data.homePage.landingPageTitle}
          linkImage={`https:${data.homePage.landingPagePreviewImage.file.url}`}
          />
          <MainImage fluid={data.homePage.landingPageBanner.fluid}>
            <GradientHolder>
            <Container>
              <TextContainer>
                <LandingPageText>{data.homePage.landingPageBannerTagline}</LandingPageText>
              </TextContainer>
              {/* <ButtonContainer>
                <Button className="join" href="https://www.conservatives.com/join">Join</Button>
                <Button className="donate" href="https://donate.conservatives.com/">Donate</Button>
                <Button className="get-involved" to="/contact">Get Involved</Button>
              </ButtonContainer> */}
            </Container>
            </GradientHolder>
          </MainImage>
          <LowerSection>
            <Header className="top">{data.homePage.missionTitle}</Header>
            <Values className="mission">{documentToReactComponents(data.homePage.mission.json, options)}</Values>
            <Break>
              <div className="top-line"></div>
            </Break>
            <Header>{data.homePage.visionTitle}</Header>
            <Values className="mission">{documentToReactComponents(data.homePage.vision.json, options)}</Values>
            <Break>
              <div className="top-line"></div>
            </Break>
            <Header>{data.homePage.valuesTitle}</Header>
            <Values className="mission">{documentToReactComponents(data.homePage.values.json, options)}</Values>
            <Break>
              <div className="top-line"></div>
            </Break>
            <Header>{data.homePage.taskTitle}</Header>
            <Values className="mission">{documentToReactComponents(data.homePage.task.json, options)}</Values>
            <Break>
              <div className="line"></div>
            </Break>
            <Intro>{documentToReactComponents(data.homePage.landingPageIntroduction.json, options)}</Intro>
          </LowerSection>
          <ValuesList />
          <Break>
              <div className="line"></div>
          </Break>
          <ArticleList />
          {/* <ContactForm />
          <LandingPageTagline />
          <NewsList />
          <JoinBanner /> */}
        </Layout>
     )
   
};


export default IndexPage;

export const pageQuery = graphql`
  query {
    homePage: contentfulLandingPage {
      landingPageTitle
      landingPageLinkTitle
      landingPageLinkDescription
      landingPageBanner {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid
        }
      }
      landingPageBannerTagline
      landingPagePreviewImage {
        file {
          url
        }
      }
      landingPageIntroduction {
        json
      }
      missionTitle
      mission {
        json
      }
      visionTitle
      vision {
        json
      }
      valuesTitle
      values {
        json
      }
      taskTitle
      task {
        json
      }
    }
    site {
      siteMetadata {
        url
      }
    }   
  }
`;

const MainImage = styled(BackgroundImage)`
`
const GradientHolder = styled.div`
  background: linear-gradient(to left, transparent 35%, rgba(0, 0, 0, 0.5) 80%);
  @media (max-width: 769px) {
    margin-top: 5rem;
  }
`
const Container = styled.div`
  height: 100vh;
  max-width: 1200px;
  padding: 0px 50px;
  margin-left: auto;
  margin-right: auto;
  

  display: flex;
  flex-direction: column;
  justify-content: center;


  @media (max-width: 769px) {
    padding: 0px 2rem;
    height: 40vh;
  }

  @media (max-width: 450px) {
    height: 30vh;
  }
`

const TextContainer = styled.div`
  width: 55%;

  @media (max-width: 769px) {
    text-align: center !important;
    
  }
  @media (max-width: 450px) {
    text-align: center !important;
    
  }
  
`

const LandingPageText = styled.h1`
  color: #ffffff;
  display: inline;
  font-family: 'Cormorant Garamond', serif !important;
  font-size: 50px;
  font-weight: 700;
  font-style: italic;
  line-height: 70px;

  @media (max-width: 769px) {
    margin-left: 0;    
  }

  @media (max-width: 450px) {
    font-size: 6vw;
    line-height: 40px;

    text-align: center !important;
    .mobile{
      display: inline;
    }
  }
  
`

const ButtonContainer = styled.div`
  margin-left: -10px;
  padding-top: 10px;

  .join{
    background-color: #e21e26 !important;
    margin-right: 10px;

    &:hover{
      background-color: #ec4c53 !important;
      transition: all 300ms linear 0s ;
    }
  }

  .donate{
    background-color: #fec12b !important;
    color: #000000 !important;

    &:hover{
      background-color: #fdd05f !important;
      transition: all 300ms linear 0s ;
    }
  }

  .get-involved{
    background-color: #0a3b7c !important;
    margin-left: 10px;

    &:hover{
      background-color: #1c62be !important;
      transition: all 300ms linear 0s ;
    }
  }

  @media (max-width: 769px) {
    margin-left: auto;
    margin-right: auto;
    
  }

  @media (max-width: 450px) {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .join{
      margin-right: 0px;
    }

    .donate{
      margin-top: 10px;
    }

    .get-involved{
      margin-top: 10px;
      margin-left: 0px;
    }
  }
`

const Button = styled(Link)`
  text-decoration: none !important;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 700;
  display: inline-block;
  padding: 10px 35px;
  border-radius: 4px;
  line-height: 24px;
  
  
`

const LowerSection = styled.div`
  background-color: #FFFFFF;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Cormorant Garamond', serif !important;
  padding-top: 30px;
  @media (max-width: 450px) {
      padding-left: 5px;
      padding-right: 5px;
    }
`

const Intro = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: black;
  line-height: 24px !important;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: justify;

  @media (max-width: 450px) {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 4.3vw;
 
  }
`

const Break = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding: 0 30px;
  .line{
    max-width: 1000px;
    height: 3px;
    margin: 20px auto;
    background-color: #C4C4C4;
    border-radius: 3px;
    
  }

  .top-line{
    max-width: 300px;
    height: 3px;
    margin: 20px auto;
    background-color: #C4C4C4;
    border-radius: 3px;
  }
  
`

const Header = styled.h1`
  color: #004225;
  text-align: center;
  font-size: 38px;
  font-weight: 900;
  padding: 10px;
  margin-bottom: 0;
  font-family: 'Raleway', sans-serif !important;
  text-align: center !important;
  

  @media (max-width: 450px) {
    font-size: 8vw;
    
  }
`

const Values = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: black;
  line-height: 24px !important;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 40px;
  margin-bottom: 40px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 450px) {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 4.3vw;
    text-align: justify;
 
  }
`